import {CONFIG, CONNECTION} from '../../helper'

const path = process.env.REACT_APP_ENDPOINT_COUNTRY

export const getCountries = async () => {
  const config = CONFIG({url: path + '/countries'})
  return await CONNECTION(config)
}

export const getCountryProfile = async () => {
  const config = CONFIG({url: path + '/countryprofiles'})
  return await CONNECTION(config)
}
